import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import Logo from "../images/hemsidan-logo.png"
import SEO from "../components/seo"

import ReactGA from "react-ga"

const IndexPage = () => {
  useEffect(() => {
    ReactGA.initialize("UA-167954227-1")
    ReactGA.pageview("/smart-brf-splash")
  }, [])

  const handleGoToGrannskap = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Grannskap Link",
    })
    window.location = "https://grannskap.se/"
  }

  return (
    <Layout>
      <SEO
        title="Hemma på nätet"
        description="Grannskap® är en digital plattform för grannsämja och grannsamverkan."
        author="Grannskap AB"
      />
      <div className="relative bg-white overflow-hidden">
        <div className="max-w-screen-xl mx-auto ">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:min-h-screen lg:pb-28 xl:pb-32  lg:flex lg:flex-col lg:justify-center">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
              <nav className="relative flex items-center justify-between sm:justify-center lg:justify-between sm:h-10 lg:justify-start">
                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                  <div className="flex items-center justify-between sm:justify-center lg:justify-between w-full lg:w-auto">
                    <img
                      src={Logo}
                      alt="Hemsidan.com Logotyp"
                      title="Hemsidan.com Logotyp"
                      className="h-12 w-auto sm:h-16"
                    />
                  </div>
                </div>
              </nav>
            </div>

            <div className="mt-10 mx-auto lg:mx-0 max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                  Hemsidan.com blir
                  <br />
                  <span className="text-primary">
                    Grannskap<span className="text-xl">®</span>
                  </span>
                </h2>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Vår populära webbtjänst för bostadsrättsföreningar kommer
                  under 2022 bli en del av Grannskap. Vi är samma team bakom
                  tjänsten och även det framgångsrika konceptet förblir det
                  samma - men i en helt ny uppdaterad plattform. Många spännande
                  nyheter och förbättringar är att vänta! <br />
                  <br />I väntan på lanseringen har vi nu stängt ned denna sajt,
                  hemsidan.com, och hänvisar till intresseanmälan på
                  grannskap.se. Varmt välkomna!
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <a
                      href="#"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary hover:bg-primary-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                      onClick={() => handleGoToGrannskap()}
                    >
                      Gå till Grannskap©
                    </a>
                  </div>
                </div>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Befintlig kund som behöver support? support@hemsidan.com
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <Image />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
